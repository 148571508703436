import React from "react";
import styled from "styled-components";

export default function Toggler({ theme = true, setTheme, place }) {
  return (
    <Container
      className={`${theme ? "light" : "dark"} ${place}`}
      onClick={() => setTheme(!theme)}
      title={theme ? "Switch to dark theme" : "Switch to light theme"}
    >
      <div className="toggler_circle"></div>
    </Container>
  );
}

const Container = styled.div`
  width: 56px;
  height: 32px;
  border-radius: 16px;
  padding: 0 5px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: var(--black);
  &.dark {
    justify-content: flex-end;
  }
  .toggler_circle {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: var(--white);
  }
  &.menu {
    background: var(--footer-color);
    .toggler_circle {
      background: var(--footer-bg);
    }
  }
`;
