import React from "react";
import styled from "styled-components";

export default function Hero() {
  const Quotes = [
    'Even the ugliest places can be beautiful - as long as you take the time to look.',
    'We keep this love in a photograph, We made these memories for ourselves',
  ];
  
  return (
    <Container>
      <div className="hero_section">
        <div className="hero_text">
          <h1>
            “{Quotes[0]}”
          </h1>
        </div>
      </div>
    </Container>
  );
}

const Container = styled.section`
  display: flex;
  flex-direction: column;
  .hero_text {
    & > h1 {
      font-weight: 300;
      font-size: calc(20.86px + 2vmin);
      /* font-size: 36px; */
      line-height: 100%;
      text-align: center;
      text-transform: uppercase;
      font-family: var(--font-s);
      color: var(--black);
      @media (max-width: 548px) {
        font-size: calc(8px + 2vmin);
        line-height: 97%;
        letter-spacing: 0.01em;
      }
    }
  }
  .hero_section {
    max-width: 1114px;
    margin: 0 auto;
    @media (max-width: 1140px) {
      padding: 0 50px;
    }
    @media (max-width: 548px) {
      padding: 0px 26px;
    }
  }
`;
