import React from "react";
import styled from "styled-components";

export default function Nav({ classes = "normal" }) {
  const linksName = ["ahjim.com", "About", "Blog"];
  const links = [
    "https://ahjim.com/",
    "https://ahjim.com/about/",
    "https://dev.to/akhlakhossainjim",
  ];

  return (
    <Container className={classes}>
      {React.Children.toArray(
        linksName.map((data, i) => (
          <a href={links[i]} className="nav_link">
            {data}
          </a>
        ))
      )}
    </Container>
  );
}

const Container = styled.nav`
  display: flex;
  gap: 32px;
  color: var(--black);
  @media (max-width: 548px) {
    flex-direction: column;
  }
  &.normal {
    .nav_link {
      color: var(--black);
      text-decoration: none;
      cursor: pointer;
      font-size: 15px;
      line-height: 21px;
      position: relative;
      @media (max-width: 548px) {
        font-size: 17px;
        line-height: 23px;
      }
      &:after {
        content: "";
        position: absolute;
        bottom: -12px;
        left: 50%;
        transform: translateX(-50%);
        width: 24px;
        height: 2px;
        background: transparent;
        border-radius: 1px;
      }
      &:hover {
        &:after {
          background: var(--black);
        }
      }
    }
  }
  &.menu_navigation {
    flex-direction: column;
    font-family: var(--font-s);
    font-size: 27px;
    line-height: 27px;
    text-transform: uppercase;
    gap: 29px;
    margin-top: 50px;
    .nav_link {
      color: var(--footer-color);
      text-decoration: none;
    }
  }
  &.footer_navigation {
    .nav_link {
      color: var(--footer-color);
      text-decoration: none;
    }
  }
`;
