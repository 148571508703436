import React from "react";
import styled from "styled-components";
import db from "../firebase";
import Modal from "./Modal";

export default function Photos() {
  const [DATA, setDATA] = React.useState();

  const [PhotoData, setPhotoData] = React.useState();
  const [ModalStat, setModalStat] = React.useState(false);

  const handleClick = (data) => {
    setModalStat(true);
    setPhotoData(data);
  };

  console.log(PhotoData, ModalStat);

  React.useEffect(() => {
    db.collection("photograph").onSnapshot((snapshot) => {
      let items = [];
      snapshot.docs.forEach((doc) => {
        items.push({
          id: doc.id,
          ...doc.data(),
        });
      });
      setDATA(items);
    });
  }, []);

  return (
    <>
      <Container>
        {DATA ? (
          React.Children.toArray(
            DATA.map((data, index) => (
              <div
                className="photo_container"
                onClick={() => handleClick(index)}
              >
                <img
                  src={data.image && data.image}
                  alt={data.title && data.title}
                  loading="lazy"
                />
              </div>
            ))
          )
        ) : (
          <p>No data available. Please wait or reload your window.</p>
        )}
      </Container>

      {ModalStat ? (
        <Modal
          data={DATA}
          index={PhotoData}
          setIndex={setPhotoData}
          setModal={setModalStat}
        />
      ) : (
        ""
      )}
    </>
  );
}

const Container = styled.section`
  display: flex;
  /* align-items: stretch;
  justify-content: center; */
  /* flex: content; */
  flex-wrap: wrap;
  max-width: 1114px;
  margin: 0 auto;
  padding: 64px 0px;
  min-height: calc(100vh - 400px);
  gap: 16px;
  @media (max-width: 1140px) {
    padding: 64px 50px;
  }
  @media (max-width: 548px) {
    padding: 46px 26px;
  }
  .photo_container {
    /* width: min(370.33px, 100%); */
    height: 40vh;
    flex-grow: 1;
    @media (max-width: 548px) {
      width: 100%;
      height: auto;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      vertical-align: bottom;
    }
  }
  p {
    font-size: calc(10px + 2vmin);
    text-align: center;
    width: 100%;
  }
`;
