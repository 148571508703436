import React from "react";
import styled from "styled-components";
import MobileMenu from "./MobileMenu";
import Nav from "./Nav";
import Toggler from "./Toggler";

export default function Header({ theme, setTheme }) {
  const [hamOpenState, setHamOpenState] = React.useState(false);
  return (
    <>
      <Container>
        <div className="header_logo">
          <img
            src={`/images/icons/logo-${theme ? "light" : "dark"}.svg`}
            alt="logo"
          />
        </div>
        <div className="header_menus">
          <Nav />
          <Toggler theme={theme} setTheme={setTheme} />
        </div>
        <div
          className="header__ham"
          onClick={() => setHamOpenState(!hamOpenState)}
        >
          <img
            src={`/images/icons/icon-hamburger-${theme ? "light" : "dark"}.svg`}
            alt="menu"
          />
        </div>
      </Container>
      {hamOpenState && (
        <MobileMenu
          setHamOpenState={setHamOpenState}
          theme={theme}
          setTheme={setTheme}
        />
      )}
    </>
  );
}

const Container = styled.header`
  padding: 64px 2px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1114px;
  margin: 0 auto;
  @media (max-width: 1140px) {
    padding: 50px;
  }
  @media (max-width: 548px) {
    padding: 46px 27px;
  }
  .header {
    &_logo {
      width: min(69.26px, 100%);
      & > img {
        width: 100%;
        height: auto;
        margin: auto;
      }
    }
    &_menus {
      display: flex;
      gap: 32px;
      align-items: center;
      @media (max-width: 768px) {
        display: none;
      }
    }
    &__ham {
      cursor: pointer;
      @media (min-width: 769px) {
        display: none;
      }
    }
  }
`;
