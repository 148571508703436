import React from "react";
import styled from "styled-components";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Hero from "./components/Hero";
import Photos from "./components/Photos";

function App() {
  const [IsLight, setIsLight] = React.useState(true);

  React.useEffect(() => {
    IsLight
      ? document.body.classList.remove("dark")
      : document.body.classList.add("dark");
  }, [IsLight]);

  return (
    <Container>
      <Header theme={IsLight} setTheme={setIsLight} />
      <main>
        <Hero />
        <Photos />
      </main>
      <Footer />
    </Container>
  );
}

const Container = styled.div`
  max-width: 1440px;
  margin: 0 auto;
`;

export default React.memo(App);
