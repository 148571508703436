import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDtwnUS0sKq4SGQI_zgx24F2dDWyzKpOYE",
  authDomain: "portfolio-data-jim.firebaseapp.com",
  projectId: "portfolio-data-jim",
  storageBucket: "portfolio-data-jim.appspot.com",
  messagingSenderId: "991386217148",
  appId: "1:991386217148:web:942d0acc3143ff461b7eb9",
  measurementId: "G-4B1LTKMTCB",
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
const db = firebaseApp.firestore();

export default db;
