import React, { useEffect, useState } from "react";
import styled from "styled-components";

export default function Modal({ setModal, data, index, setIndex }) {
  const [DATA, setDATA] = useState();

  useEffect(() => {
    setDATA(data[index]);
  }, [index]);

  return (
    <Container>
      <div className="close_modal" onClick={() => setModal(false)}></div>
      {DATA && (
        <>
          <div className="modal_image">
            <img
              src={DATA.image && DATA.image}
              alt={DATA.title && DATA.title}
            />
          </div>
          {DATA.title && (
            <div className="modal_title">
              <h3>{DATA.title}</h3>
            </div>
          )}
        </>
      )}
      <div
        className="modal_goLeft"
        onClick={() =>
          index === 0 ? setIndex(data.length - 1) : setIndex(index - 1)
        }
      ></div>
      <div
        className="modal_goRight"
        onClick={() =>
          index === data.length - 1 ? setIndex(0) : setIndex(index + 1)
        }
      ></div>
    </Container>
  );
}

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100vw;
  min-height: 100vh;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 700;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px;
  .modal {
    &_image {
      position: relative;
      height: 80vh;
      & > img {
        /* object-position: center; */
        width: 100%;
        height: 100%;
        object-fit: scale-down;
      }
    }
    &_title {
      background-color: white;
      color: black;
      padding: 16px 32px;
    }
    &_go {
      &Left {
        position: absolute;
        top: 50%;
        left: 3%;
        background: white;
        width: 20px;
        height: 2px;
        transform: rotate(-45deg) translateY(50%);
        cursor: pointer;
        &::before {
          content: "";
          position: absolute;
          top: 9px;
          left: -9px;
          background: white;
          width: 20px;
          height: 2px;
          transform: rotate(90deg);
        }
      }
      &Right {
        position: absolute;
        top: 50%;
        right: 3%;
        background: white;
        width: 20px;
        height: 2px;
        transform: rotate(45deg) translateY(50%);
        cursor: pointer;
        &::before {
          content: "";
          position: absolute;
          top: 9px;
          right: -9px;
          background: white;
          width: 20px;
          height: 2px;
          transform: rotate(90deg);
        }
      }
    }
  }
  .close_modal {
    position: absolute;
    top: 32px;
    right: 40px;
    background: white;
    width: 20px;
    height: 2px;
    transform: rotate(45deg);
    cursor: pointer;
    &::before {
      content: "";
      position: absolute;
      top: 0px;
      right: 0px;
      background: white;
      width: 20px;
      height: 2px;
      transform: rotate(90deg);
    }
  }
`;
